import * as React from "react";
import {Link} from "gatsby";
import {ASSETS} from "../common/images";
import Slider from "react-slick";

const LifeAtGeekybones = () => {
    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }: any) => (
        <button
            {...props}
            className={
                "common-life-geeky-next-prev-btn life-geeky-prev-btn" +
                (currentSlide === 0 ? " slick-disabled" : "")
            }
            aria-hidden="true"
            aria-disabled={currentSlide === 0}
            type="button"
        >
            <img src={ASSETS.SERVICES.SliderArrowLeftWhite} className="bg-sm-img" alt="Arrow|GeekyBones"/>
        </button>
    );
    const SlickArrowRight = ({ currentSlide, slideCount, ...props }: any) => (
        <button
            {...props}
            className={
                "common-life-geeky-next-prev-btn life-geeky-next-btn" +
                (currentSlide === slideCount - 1 ? " slick-disabled" : "")
            }
            aria-hidden="true"
            aria-disabled={currentSlide === slideCount - 1}
            type="button"
        >
            <img src={ASSETS.SERVICES.SliderArrowRightWhite} className="bg-sm-img" alt="Arrow|GeekyBones" />
        </button>
    );

    const settings = {
        dots: false,
        arrows: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <SlickArrowLeft />,
        nextArrow: <SlickArrowRight />,
        swipeToSlide: true,
        autoPlay: true,
        autoplaySpeed: 1000,
    };

    const lifeAtGeekyList = [
        {
           imgUrl: ASSETS.TRIP.GeekyFun1,
        },
        {
           imgUrl: ASSETS.TRIP.GeekyFun2,
        },
        {
           imgUrl: ASSETS.TRIP.AugCelebration,
        },
        {
           imgUrl: ASSETS.TRIP.anniversary4Cake,
        },
        {
           imgUrl: ASSETS.TRIP.AnniversaryCakeCutting,
        },
        {
           imgUrl: ASSETS.TRIP.TeamGroup,
        },
        {
           imgUrl: ASSETS.TRIP.TeamParty,
        },
    ]
    return (
        <section className="life-at-geekybones-wrapper gb-md-pad-ver common-about-img-text-wrapper">
            <div className="container-fluid p-md-0">
                <div className="row d-flex align-items-center">
                    <div className="col-md-6">
                        <div className="about-common-description">
                            <h2>Life at GeekyBones</h2>
                            <p>We begin our day with coffee and discussions!</p>
                            <p>Our work, passion, and especially our culture define us and sets us apart.</p>
                            <p>Our discussions help us in sharing new ideas where ingenious solutions are born. Our celebrations rejuvenate and strengthen our bond.</p>
                            <Link to={'/trip-to-hail-himalayas'} className="text-uppercase gb-animate-sm-btn-red">see more</Link>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <Slider {...settings}>
                            {lifeAtGeekyList.map((row, index) => (
                        <div className="common-img-wrapper" key={index}>
                            <img src={row.imgUrl} alt="Celebrations|GeekyBones" className="common-img"/>
                        </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default LifeAtGeekybones;
