import * as React from "react";
import Layout from "../../components/layout";
import HistoryInWords from "../../components/about/history-in-words";
import OurMission from "../../components/about/our-mission";
import MeetOutLeaders from "../../components/about/meet-out-leaders";
import LifeAtGeekybones from "../../components/about/life-at-geekybones";
import OurCoreValues from "../../components/about/our-core-values";

const AboutPage = () => {
  return (
      <Layout headerCustomClass={'gb-header-main-wrapper-inner'} pageTitle={'About us'} currentPage={'About'} breadcrumbView={true} metaTitle={'GeekyBones | Build and Grow your Business Digitally, and Globally'} metaDescription={'GeekyBones is your true development partner offering high-quality IT services. Let us talk solutions and grow digitally!'}>
          <OurMission />
          <HistoryInWords />
          <MeetOutLeaders />
          <LifeAtGeekybones />
          <OurCoreValues />
      </Layout>
  )
}
export default AboutPage;
