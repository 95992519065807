import * as React from "react";
import {ASSETS} from "../common/images";
import {Link} from "gatsby";

const HistoryInWords = () => {
    return (
        <section className="histroy-sec container-fluid gb-md-pad-ver">
            <div className="container">
                <div className="blue-red-heading common-heading-underline histroy-sec-title-wrapper">
                    <h2>Our Background <span>History</span></h2>
                    <p>In the early stages, we have tasted the success and glory that we want to share with you.</p>
                </div>
                <div className="history-inner">
                    <img src={ASSETS.ABOUT.RoundCicleTop} alt="Vertical-Chain|GeekyBones" className="common-top-bottom-round" />
                        <div className="left-side">
                            <ul>
                                <li>
                                    <img src={ASSETS.ABOUT.CircleLeft} alt="Horizontal-Chain|GeekyBones" className="common-top-cirle-left" />
                                    <h3>JULY, 2021</h3>
                                    <h4>Celebrating our Achievements</h4>
                                    <p>While attaining new achievements, we have increased our team to 21 members. We celebrated our 4th birthday in the Hail Himalayas.</p></li>
                            </ul>
                        </div>
                        <div className="right-side">
                            <ul>
                                <li>
                                    <img src={ASSETS.ABOUT.CircleRight} alt="Horizontal-Chain|GeekyBones" className="common-top-cirle-right" />
                                    <h3>JULY, 2020</h3><h4>Working on Multiple Platforms</h4><p>And, we started working on multiple platforms with various technologies. We are now offering services including Mobile App, Website development and designing.</p></li>
                            </ul>
                        </div>
                        <div className="left-side">
                            <ul>
                                <li>
                                    <img src={ASSETS.ABOUT.CircleLeft} alt="Horizontal-Chain|GeekyBones" className="common-top-cirle-left" />
                                    <h3>JULY, 2019</h3><h4>Meeting New Challenges</h4><p>We have been continuously working and learning, striving to bring results in everything that we are doing.</p></li>
                            </ul>
                        </div>
                        <div className="right-side">
                            <ul>
                                <li>
                                    <img src={ASSETS.ABOUT.CircleRight} alt="Horizontal-Chain|GeekyBones" className="common-top-cirle-right" />
                                    <h3>JULY, 2018</h3><h4>1st Birthday</h4><p>We completed one year of our Journey. This year, we passed through many obstacles, but we never stopped, and now we're a team of 8 professionals.</p></li>
                            </ul>
                        </div>
                        <div className="left-side">
                            <ul>
                                <li>
                                    <img src={ASSETS.ABOUT.CircleLeft} alt="Horizontal-Chain|GeekyBones" className="common-top-cirle-left" />
                                    <h3>FEB, 2018</h3><h4>Acquired, DesigningTips.com</h4><p>We've acquired designingtips.com.</p></li>
                            </ul>
                        </div>


                    <div className="right-side">
                        <ul>
                            <li>
                                <img src={ASSETS.ABOUT.CircleRight} alt="Horizontal-Chain|GeekyBones" className="common-top-cirle-right" />
                                <h3>JAN, 2018</h3><h4>Now a Team</h4><p>Originating from a single man startup has now a number of dedicated professionals and experts working systematically to achieve the goals.</p></li>
                        </ul>
                    </div>
                    <div className="left-side">
                        <ul>
                            <li>
                                <img src={ASSETS.ABOUT.CircleLeft} alt="Horizontal-Chain|GeekyBones" className="common-top-cirle-left" />
                                <h3>DEC, 2017</h3><h4>An Incorporated Organization</h4><p>On the 29th day of December, we got incorporated with the Ministry of Corporate Affairs, India, as the Private Limited Company with the Incorporation Number (CIN): U72900HR2017PTC072035.
                            </p></li>
                        </ul>
                    </div>
                    <div className="right-side">
                        <ul>
                            <li>
                                <img src={ASSETS.ABOUT.CircleRight} alt="Horizontal-Chain|GeekyBones" className="common-top-cirle-right" />
                                <h3>JULY, 2017</h3><h4>Beginning</h4><p>We set the foundation of Geeky Bones on the 15th day of July. After that, the idea and the dream, which was just in the minds of the founders, came into existence, and the world noticed a steady growth in it.</p></li>
                        </ul>
                    </div>
                    <img src={ASSETS.ABOUT.RoundCicleBottom} alt="Rounded-Circle|GeekyBones" className="common-top-bottom-round bottom-round-icon"  />
                 </div>
            </div>
        </section>
    )
}
export default HistoryInWords;
